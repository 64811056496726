import { useState } from "react"
import axios from "axios"

function CTA() {
  const [email, setEmail] = useState("")
const [alert, setAlert] = useState(false)

  const emailchange = (e) => {
    setEmail(e.target.value)
}


const submits = (e) => {
  e.preventDefault()


  axios.post("https://api.brevo.com/v3/contacts", { "email": email, "listIds": [2], }, { headers: { "accept": 'application/json', "api-key": process.env.REACT_APP_API_KEY } })
      .then(response => {if(response.data.id){
          setEmail("") 
          setAlert(true)
      }})
}

    return (
      <section
        style={{
          width: "100%",
          padding: "5rem 0",
          backgroundColor: "white",
        }}
      >
        <div
          className="container"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            gap: "2rem",
          }}
        >
          <h2
            style={{
              fontSize: "clamp(2rem, 4vw, 3rem)",
              fontWeight: "bold",
            }}
          >
            Gatavs mainīt veidu, kā pārvaldīt digitālo?
          </h2>
  
          <p
            style={{
              fontSize: "1.25rem",
              color: "#4b5563",
              maxWidth: "36rem",
            }}
          >
            Sāc savu ceļojumu šodien. Atstāj e-pastu un mēs sazināsimies
          </p>
  
          <form
            style={{
              width: "100%",
              maxWidth: "28rem",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
            onSubmit={submits}
          >
            <input
              style={{
                width: "93%",
                padding: "0.75rem 1rem",
                borderRadius: "9999px",
                border: "1px solid #d1d5db",
                fontSize: "1rem",
              }}
              type="email"
              placeholder="Jūsu e-pasta adrese"
              onChange={emailchange} required
            />
  
  {alert
   ? <div className="alert">Jūsu e-pasts nosūtīts. Drīz saņemsiet ziņu. Ja nesaņemat to šodien, pārbaudiet <i>spama</i> kastīti.</div> :  <button
              style={{
                width: "100%",
                backgroundColor: "black",
                color: "white",
                padding: "0.5rem",
                borderRadius: "9999px",
                fontSize: "1.125rem",
                fontWeight: 500,
              }}
            >
              Atstāt e-pastu
            </button>}
          </form>
  
          <p
            style={{
              fontSize: "0.875rem",
              color: "#6b7280",
            }}
          >
            Reģistrējoties jūs piekrītat mūsu  Privātuma politikai.
          </p>
        </div>
      </section>
    )
  }
  
  export default CTA
  
  