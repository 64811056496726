import React from "react"
import video from "../../assets/attīstība tavam biznesam.mp4"

import AnimatedOctopus from "../../AnimatedOctopuss"

function Experience() {
  const features = [
    { title: "Publicēšana ar vienu klikšķi", description: "No melnraksta līdz tiešraidei sekundēs." },
    { title: "Intuitīva saskarne", description: "Rokasgrāmatas nav nepieciešamas." },
    { title: "Priekšskatījums reālajā laikā", description: "Redziet tieši to, ko redzēs jūsu lietotāji." },
  ]

  return (
    <section
      id="experience"
      style={{
        width: "auto",
        padding: "5rem 5rem",
        backgroundColor: "black",
        color: "white",
      }}
    >
      <div
        className="container"
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
          gap: "3rem",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", gap: "2rem", alignItems: "center" }}>
          <h2
            style={{
              fontSize: "clamp(2rem, 4vw, 3rem)",
              fontWeight: "bold",
              lineHeight: 1.2,
              color:"gray"
            }}
          >
            Pieredze
            <br />
            <span style={{ color: "#a78bfa" }}>ir svarīga</span>
          </h2>

          <p style={{ fontSize: "1.25rem", color: "#d1d5db" }}>
            Mēs pievērsām uzmanību katrai detaļai. Katrai mijiedarbībai. Katram mirklim. Jo jūsu laiks ir vērtīgs, un
            jūsu darbs ir svarīgs.
          </p>

          <div style={{ display: "flex", flexDirection: "column", gap: "1rem", alignItems: "center" }}>
            {features.map((feature, index) => (
              <div key={index} style={{ display: "flex", alignItems: "center", gap: '1rem'}}>
                <div
                  style={{
                    width: "1.5rem",
                    height: "1.5rem",
                    borderRadius: "50%",
                    backgroundColor: "#7c3aed",
                    flexShrink: 0,
                    marginTop: "1.25rem",
                  }}
                />
                <p style={{ color: "#d1d5db" }}>
                  <span style={{ fontWeight: 500, color: "white" }}>{feature.title}</span> {feature.description}
                </p>
              </div>
            ))}
          </div>
        </div>

        <div style={{ position: "relative" }}>
          <div
            style={{
              position: "relative",
              height: "400px",
              width: "100%",
              borderRadius: "0.75rem",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                position: "absolute",
                inset: 0,
              }}
            />
            <div
              style={{
                position: "absolute",
                inset: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <video width="650" height="450" controls>
                <source src={video} type="video/mp4" />
                <source src="mov_bbb.ogg" type="video/ogg" />
            </video>
            </div>
          </div>
          <AnimatedOctopus
            style={{
              position: "absolute",
              right: "4rem",
              bottom: "-2rem",
              width: "6rem",
              height: "6rem",
              opacity: 0.8,
            }}
          />
        </div>
      </div>
    </section>
  )
}

export default Experience

