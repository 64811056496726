import React, { useState, useEffect, useContext } from "react"
import {
    Button,
    TextField,
    ImageListItem,
    ImageList,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import "./gallery.css"
import axios from "axios";
import { useForm } from "react-hook-form";
import translate from "../../../../helpers/translate";
import LanguageContext from "../../../../helpers/context"
import Error from "../Message"
import ImageModal from "./imageModal";

export default function AddGallery({ close, data }) {

    const [dragActive, setDragActive] = useState(false);
    const [files, setFiles] = useState([])
    const [imagemodal, setImageModal] = useState(false)
    const [image, setImage] = useState("")
    const [imagearray, setImgArray] = useState([])
    const [isSubmitting, setSubmitting] = useState(false)
    const [status, setStatus] = useState()
    const [message, setMessage] = useState()
    const [open, setOpen] = useState(false)
    const { language } = useContext(LanguageContext)
    const [imagesrc, setImagesrc] = useState()

    const { register, handleSubmit, errors, reset } = useForm({});


    useEffect(() => {
        initdata()
    }, [])

    const initdata = () => {
        if (data.id) {
            axios.get(`${process.env.REACT_APP_API_URL}/getfiles`, { params: { data } })
                .then(response => setFiles(response.data))
        }
    }

    const handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {

            const data = e.dataTransfer.files

            Array.from(data).forEach(file => files.push({ file: URL.createObjectURL(file) }))
            setFiles([...files], files)

            Array.from(data).forEach(file => imagearray.push(file))
            setImgArray([...imagearray], imagearray)
        }
    };

    const openImg = (item) => {
        setImageModal(true)
        setImage(item.id)
        setImagesrc(item)
    }

    const handleClose = (data) => {

     if(data === "dzests"){initdata()} 
        setImageModal(false)
    }

    const handleChange = (e) => {
        const arr = [...e.target.files]
        const filesize = 2000000
        arr.forEach(element => {
            if (element.size >= filesize) {
                setMessage(translate(language, "file-size"))
                setStatus("error")
                setOpen(true)
            }
        });
        arr.forEach((file) => { if (file.size <= filesize) { files.push({ file: URL.createObjectURL(file) }) } })
        arr.forEach(file => { if (file.size <= filesize) { imagearray.push(file) } })
        setImgArray([...imagearray], imagearray)
    }

    const save = (formdata) => {
        const formData = new FormData();
        formData.append("Title", formdata.Title)

        let fileList = new DataTransfer();
        for (let i = 0; i < imagearray.length; i++) {
            let file = new File([imagearray[i]], imagearray[i].name);

            fileList.items.add(file)
        }
        for (let i = 0; i < fileList.files.length; i++) {
            formData.append("files", fileList.files[i])
        }

        if (data.id) {
            formData.append("id", data.id)
            axios.put(`${process.env.REACT_APP_API_URL}/updateGalerry`, formData, {
                withCredentials: true,
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            })
                .then((response) => {
                    setSubmitting(false)
                    close(response.data.message, response.data.status)
                })
                .catch((error) => {
                    close(error.message, "error")
                });

        } else {

            axios.post(`${process.env.REACT_APP_API_URL}/createGalerry`, formData, {
                withCredentials: true,
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            })
                .then((response) => {
                    setSubmitting(false)
                    close(response.data.message, response.data.status)
                })
                .catch((error) => {
                    close(error.message, "error")
                });
        }
    }

    const closeerror = () => {
        setOpen(false)
    }

    return (
        <>
            <Error status={status} message={message} open={open} func={closeerror} />

            <div>
                <form id="form-file-upload" onDragEnter={handleDrag}
                    onSubmit={handleSubmit(save)}
                >
                    <div style={{ marginBottom: "20px" }} className="toolbar">
                        <span>
                            <Button variant="outlined"
                                onClick={close}
                                sx={{ marginRight: "20px" }}
                            >
                                <ArrowBackIcon />
                                {translate(language, "button-back")}
                            </Button>
                            <Button
                                variant="contained"
                                sx={{ marginRight: "20px" }}
                                color="primary"
                                type="submit"
                                disabled={isSubmitting}
                            >
                                {translate(language, "button-save")}
                            </Button>
                        </span>
                    </div>
                    
                    <span className="post gallerry" >
                        <TextField
                            id="outlined-basic"
                            size="small"
                            label="Title"
                            variant="outlined"
                            name="Title"
                            fullWidth
                            defaultValue={data ? data.title : ""}
                            inputProps={{ maxLength: 102 }}
                            required
                            {...register("Title")}
                        />
                    </span>
                    <div><p>{translate(language, "file-size-help")}</p></div>
                    <div className="file-upload">

                        <input hidden type="file" name="files" id="actual-btn"
                            accept="image/png, image/jpeg, image/webp" onChange={handleChange} multiple />
                        <p>{translate(language, "drag-file")}</p>
                        <label htmlFor="actual-btn" className="upload-button">{translate(language, "choose-file")}</label>
                        {dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div>}
                    </div>
                </form>
            </div>
            <ImageList variant="standart"
                sx={{
                    gridTemplateColumns:
                        "repeat(auto-fill, minmax(150px, 1fr)) !important"
                }}
                gap={12}
                rowHeight={164}
            >
                {files.map((item, index) =>
                    <ImageListItem
                        key={index}
                    >
                        <img
                            src={item.id ? `${process.env.REACT_APP_API_URL}/uploads/${item.file}` : item.file}
                            loading="lazy"
                            onClick={() => openImg(item)}
                            className="item"
                            id={item.id}
                        />
                    </ImageListItem>
                )}
            </ImageList>

            {imagemodal && <ImageModal
                open={imagemodal}
                close={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                image={image}
                imagesrc={imagesrc}
                from="gallery"
            />}
        </>
    )
}