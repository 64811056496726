"use client"

import { useEffect, useRef } from "react"
import octopusImage from "../landing/assets/octopus2.png"


function AnimatedOctopus({ style }) {
  const octopusRef = useRef(null)

  useEffect(() => {
    const octopus = octopusRef.current
    if (!octopus) return

    let floatY = 0
    let floatDirection = 1
    let rotateAngle = 0

    const animate = () => {
      if (!octopus) return

      floatY += 0.03 * floatDirection
      if (floatY > 3) floatDirection = -1
      if (floatY < -3) floatDirection = 1

      rotateAngle += 0.1
      if (rotateAngle > 360) rotateAngle = 0

      octopus.style.transform = `translateY(${floatY}px) rotate(${Math.sin((rotateAngle * Math.PI) / 180) * 1.5}deg)`

      requestAnimationFrame(animate)
    }

    const animationId = requestAnimationFrame(animate)

    return () => {
      cancelAnimationFrame(animationId)
    }
  }, [])

  return (
    <div
      ref={octopusRef}
      style={{
        transition: "transform 0.3s ease",
        ...style,
      }}
    >
      <img
        src={octopusImage || "/placeholder.svg"}
        alt="MiniDigital Astoņkājis"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "contain",
        }}
      />
    </div>
  )
}

export default AnimatedOctopus

