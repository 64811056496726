import React, { useEffect, useState, useContext } from "react"
import CloseIcon from "@mui/icons-material/Close";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Box,
    IconButton,
    FormControl,
    InputLabel,
    OutlinedInput,
    TextField,
    Button,
    Stack,
    Switch,
    Typography,
    Select,
    MenuItem
} from '@mui/material';

import axios from "axios";
import { useForm } from "react-hook-form";
import translate from "../../../../helpers/translate";
import LanguageContext from "../../../../helpers/context"
import "./pagesGrid.css"

export default function AddPageModal({ handleClose, open, operation, selected }) {

    const { register, handleSubmit, errors, reset } = useForm();
    const [isSubmitting, setSubmitting] = useState(false)
    const { language } = useContext(LanguageContext)
    const [settings, setSettings] = useState()
    const [selectlang, setSelectlang] = useState("LV")
    const [title, setTitle] = useState("")
    const [info, setInfo] = useState("")
    const [published, setPublished] = useState(true)

    useEffect(() => {
        reset();
        getsettings()
        setTitle(selected[0]?.Name ? selected[0]?.Name : "")
        setInfo(selected[0]?.Info ? selected[0]?.Info : "")

    }, [selected])



    const getsettings = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/getsettings`, { withCredentials: true })
            .then((response) => {
                setSettings(response.data)
                setSelectlang(response.data.languagemain)
            })
    }

    const createPage = (data) => {
        setSubmitting(true)
        data.language = selectlang
        data.Name = title
        data.Info = info
        data.Published = published

        if (operation === "add") {

            axios.post(`${process.env.REACT_APP_API_URL}/addpages`, { params: { data } }, { withCredentials: true })
                .then(response => handleClose(response.data))

        } else {

            data.Selected = selected[0].id
            axios.put(`${process.env.REACT_APP_API_URL}/editpages`, { data }, { withCredentials: true })
                .then(response => handleClose(response.data))
        }
        setSubmitting(false)
    }

    const closing = () => {
        handleClose()
    }

    useEffect(()=>{
        if(operation === "edit" ){
        editmultilangpage(selectlang)
        }
  }, [selectlang])

    const languagechange = (e) => {
        setSelectlang(e.target.value)
        editmultilangpage(e.target.value)
    }

    const editmultilangpage = (lang) => {
        axios.get(`${process.env.REACT_APP_API_URL}/multilangpage`, { params: { selected: selected[0]?.id, lang } }, { withCredentials: true })
            .then(response =>
               setTitle(response.data[0]?.title ? response.data[0]?.title : lang === settings.languagemain ? selected[0].Name : "")
            )
    }

    const titleset = (e) => {
        setTitle(e.target.value)
    
    }

    const infoset = (e) => {
        setInfo(e.target.value)
    }

    const changepublished = (e) => {
        setPublished(e.target.checked)
    }
    let set = [...new Set(settings?.languagelist)]

    return (

        <Dialog
            maxWidth="xl"
            open={open}
            onClose={closing}
        >
            <DialogTitle >
                <IconButton
                    aria-label="close"
                    onClick={closing}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box
                    sx={{ width: "600px" }}>
                    <form onSubmit={handleSubmit(createPage)}>
                        <h1>{operation === "edit" && selected?.length === 1 ? translate(language, "edit-page") : translate(language, "create-page")}</h1>
                        <FormControl
                            fullWidth
                            size="small"
                            margin="normal"
                            required
                        >
                            <InputLabel htmlFor="outlined-adornment-name-page">{translate(language, "input-title")}</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-name-page"
                                type="text"
                                name="Name"
                                label={translate(language, "input-title")}
                                value={title}
                                onChange={titleset}
                                inputProps={{ maxLength: 102 }}
                            />
                        </FormControl>
                        <FormControl
                            fullWidth margin="normal"
                        >
                            <TextField
                                id="outlined-multiline-static"
                                label={translate(language, "input-page-info")}
                                multiline
                                rows={4}
                                disabled={operation === "edit" && (settings?.languagemain !== selectlang)}
                                defaultValue={operation === "edit" ? selected[0]?.Info : ""}
                                onChange={infoset}
                            />
                        </FormControl>

                        <FormControl>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography>{translate(language, "tag-draft")}</Typography>
                                <Switch defaultChecked={operation === "edit" ? (selected[0]?.Published === 1 ? true : false) : true}
                                    disabled={operation === "edit" && (settings?.languagemain !== selectlang)}
                                    onChange={changepublished}
                                />
                                <Typography>{translate(language, "tag-published")}</Typography>
                            </Stack>
                        </FormControl>

                        {settings?.enablemulti === 1 && <Box >
                            <FormControl
                                required
                                className="languageselect"
                            >
                                <InputLabel htmlFor="language" size="small">{translate(language, "input-language")}</InputLabel>
                                <Select
                                    id="language"
                                    label={translate(language, "input-language")}
                                    size="small"
                                    onChange={languagechange}
                                    defaultValue={settings?.languagemain ? settings?.languagemain : "LV"}
                                    disabled={operation === "add"}
                                >
                                    {set?.map(x =>
                                        <MenuItem key={x} value={x} >{translate(language, x)}</MenuItem>
                                    )}
                                    {!set.includes(settings?.languagemain) && <MenuItem key={settings?.languagemain} value={settings?.languagemain}>{translate(language, settings.languagemain)} </MenuItem>}
                                </Select>
                            </FormControl>
                        </Box>}

                        <Button
                            disableElevation
                            disabled={isSubmitting}
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            margin="normal"
                        >
                            {translate(language, "submit-continue")}
                        </Button>
                    </form>
                </Box>
            </DialogContent>
        </Dialog>
    )
}