function Principles() {
    const principles = [
      {
        number: 1,
        title: "Vienkāršība",
        description:
          "Mēs novācām visu, kas stāv starp jums un jūsu saturu. Nekādas sarežģītības. Nekādas mācīšanās līknes.",
      },
      {
        number: 2,
        title: "Kontrole",
        description: "Pilnīga kontrole pār jūsu digitālo klātbūtni. Publicējiet nekavējoties. Redziet izmaiņas uzreiz.",
      },
      {
        number: 3,
        title: "Skaistums",
        description:
          "Katrs pikselis ir svarīgs. Katra mijiedarbība iepriecina. Digitālā pārvaldība nekad nav bijusi tik skaista.",
      },
    ]
  
    return (
      <section
        id="product"
        style={{
          width: "90%",
          padding: "5rem 5rem",
          borderBottom: "1px solid #f3f4f6",
        }}
      >
        <div className="container">
          <h2
            style={{
              fontSize: "clamp(2rem, 4vw, 3rem)",
              fontWeight: "bold",
              textAlign: "center",
              marginBottom: "4rem",
            }}
          >
            Trīs principi, kas maina visu
          </h2>
  
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
              gap: "3rem",
            }}
          >
            {principles.map((principle) => (
              <div
                key={principle.number}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <div
                  style={{
                    width: "4rem",
                    height: "4rem",
                    borderRadius: "50%",
                    backgroundColor: "#f3e8ff",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: "1rem",
                  }}
                >
                  <span style={{ fontSize: "1.5rem", fontWeight: 500, color: "#7c3aed" }}>{principle.number}</span>
                </div>
                <h3 style={{ fontSize: "1.5rem", fontWeight: 500, marginBottom: "0.5rem" }}>{principle.title}</h3>
                <p style={{ color: "#4b5563" }}>{principle.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    )
  }
  
  export default Principles
  
  