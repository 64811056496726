import AnimatedOctopuss from "../AnimatedOctopuss"
import image from "../assets/image.webp"

function Hero() {
  return (
    <section
      style={{
        width: "100%",
        padding: "5rem 0",
        borderBottom: "1px solid #f3f4f6",
      }}
    >
      <div
        className="container"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          gap: "2rem",
        }}
      >
        <h1
          style={{
            fontSize: "clamp(2.5rem, 5vw, 4rem)",
            fontWeight: "bold",
            letterSpacing: "-0.025em",
            maxWidth: "64rem",
          }}
        >
          Mājas lapu pārvaldība.
          <br />
          <span style={{ color: "#7c3aed" }}>Vienkārši, bez liekā.</span>
        </h1>

        <p
          style={{
            fontSize: "clamp(1.25rem, 2vw, 1.5rem)",
            color: "#4b5563",
            maxWidth: "36rem",
          }}
        >
          Viens rīks. Bezgalīgas iespējas. Digitālā pieredze, kādu vienmēr esat vēlējies.
        </p>

        <div
          style={{
            position: "relative",
            width: "100%",
            maxWidth: "48rem",
            aspectRatio: "16/9",
            marginTop: "2rem",
          }}
        >
          {/* Placeholder for product visualization */}
          <img
            style={{
              position: "absolute",
              inset: 0,
              backgroundColor: "#f9fafb",
              borderRadius: "1rem",
              overflow: "hidden",
            }}
             src={image}
             width="768px"
          />

          <AnimatedOctopuss
            style={{
              position: "absolute",
              right: "-4rem",
              top: "-4rem",
              width: "4rem",
              height: "4rem",
            }}
          />
        </div>
        <a href="/login">
        <button
          style={{
            backgroundColor: "black",
            color: "white",
            padding: "1rem 2rem",
            borderRadius: "9999px",
            fontSize: "1.125rem",
            fontWeight: 500,
            marginTop: "2rem",
          }}
        >
          Piedzīvo MiniDigital
      
        </button>
        </a>
      </div>
    </section>
  )
}

export default Hero

