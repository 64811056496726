import React from "react"
import logo from "../../assets/logo.png"
import "./header.css"

export default function Header(){

    return(
        <header>
            <img src={logo} width="100px" height="auto" alt="minidigital logo"/>
            <a href="/login"> <button className="button">Pieslēgties</button></a>
        </header>
    )
}