import logo from "../../assets/logo.png"

function Footer() {
  return (
    <footer
      style={{
        width: "100%",
        padding: "2rem 0",
        borderTop: "1px solid #f3f4f6",
      }}
    >
      <div
        className="container"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
          }}
        >
          <img src={logo || "/placeholder.svg"} alt="MiniDigital Logo" style={{ width: "80px", height: "auto" }} />
          <span style={{ fontSize: "0.875rem", color: "#4b5563" }}>© 2025 MiniDigital by Frogit.lv</span>
        </div>

        <div
          style={{
            display: "flex",
            gap: "2rem",
          }}
        >
          <a href="https://frogit.lv/privacy" target="_blank" style={{ fontSize: "0.875rem", color: "#4b5563" }}>
            Privātums
          </a>
       
        </div>
      </div>
    </footer>
  )
}

export default Footer

